import { createRoot } from "react-dom/client";

import { Application } from "./Application";

export { render };

async function render(pageContext) {
  const { Page, pageProps } = pageContext;

  if (!Page)
    throw new Error(
      "Client-side render() hook expects pageContext.Page to be defined"
    );
  const root = document.getElementById("react-root");
  if (!root) throw new Error("DOM element #react-root not found");
  createRoot(root).render(
    <Application pageContext={pageContext}>
      <Page {...pageProps} />
    </Application>
  );
}
