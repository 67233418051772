import "../src/styles/css/style.css";

import PropTypes from "prop-types";
import React from "react";

import { ApplicationErrorBoundary } from "../src/components/error/ApplicationErrorBoundary";
import { SentryInitialiser } from "../src/utils/SentryInitialiser";
import { childrenPropType } from "./PropTypeValues";
import { PageContextProvider } from "./usePageContext";

export { Application };

Application.propTypes = {
  pageContext: PropTypes.any,
  children: childrenPropType,
};
function Application({ pageContext, children }) {
  return (
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <ApplicationErrorBoundary
          errorCallback={() => console.error("Error occured")}
        >
          {children}

          <SentryInitialiser ssr />
        </ApplicationErrorBoundary>
      </PageContextProvider>
    </React.StrictMode>
  );
}
